import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookDead } from '@fortawesome/free-solid-svg-icons';

export default function DeadComponent() {
    return (
        <section className="main__content">
            <div className="main__gutter">
                <h1><span className="content__Headline">404</span></h1>
                <p>This content was not meant for mere mortals.</p>
                <FontAwesomeIcon icon={faBookDead} />
            </div>
        </section>
    );
}