import "./App.css";
import MainContent from "./components/MainContent";
import Header from "./components/Header";
import ProjectsContent from "./components/ProjectsContent";
import ResumeContent from "./components/ResumeContent";
import DeadContent from "./components/DeadContent";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";

const Home = () => (
  <MainContent/>
) 

const Projects = () => (
  <ProjectsContent/>
) 

const Resume = () => (
  <ResumeContent/>
)

const Dead = () => (
  <DeadContent/>
) 

function App() {
    return (
        <Router>
            <Header/>
            <main className="main">
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/projects" component={Projects} />
                    <Route path="/resume" component={Resume} />
                    <Route path="*" component={Dead} statu={404}/>
                </Switch>
            </main>
        </Router>
    );
}

export default App;
