import React from "react";

export default function ResumeContent() {
    return (
        <React.Fragment>
            <section className="main__gutter main__gutter--employment">
                <div className="main__content">
                    <h1><span className="highlighted">10 Year Resume</span></h1>
        
                        <div className="resume__employment">
                            <h2 className="resume__company"><span className="highlighted">Union Street Media</span>: Feb. 2014 - Present</h2>
                            <h3 className="resume__title"><span className="highlighted">Software Engineer</span>: Dec. 2018 - Present</h3>
                            <ul className="resume__list">
                                <li className="resume__item">Helped lay foundation for platform wide refresh by providing the skill sets of Frontend Engineer and SEO Engineer.</li>
                                <li className="resume__item">Planned and developed React UI for new map search product overlaying both legacy PHP and new API backends.</li>
                                <li className="resume__item">Wrote UI, unit, and integration tests for new products.  Documented product funtionality.</li>
                                <li className="resume__item">Voice for future platform direction to align with search engine crawlability and discoverability.</li>
                                <li className="resume__item">Responsible for identifying flaws and modernizing existing "V1" infrastructure to meet current search engine expectations.</li>
                                <li className="resume__item">Handled site-by-site technical audits for strategic clients to improve site performance.</li>
                                <li className="resume__item">Scrum/Agile development methodology with peer-engineer code reviews.</li>
                            </ul>
                            <h3 className="resume__title"><span className="highlighted">Production Manager</span>: Dec. 2017 - Dec. 2018</h3>
                            <ul className="resume__list">
                                <li className="resume__item">Lead a multi-discipline and distributed team of six. Coordinated contractors.</li>
                                <li className="resume__item">Held bi-monthly check ins with all direct reports to align focus and gather feedback on progress and workplace morale.</li>
                                <li className="resume__item">Tracked all facets of parallel projects from start to finish. Used collected data to pinpoint operational bottlenecks and later implemented process changes to correct for them.  Reduced turnkey build time from ~10hrs down to ~2hrs.</li>
                                <li className="resume__item">Responsible for hire/fire decision in the production team. Onboarded all new staff in company.</li>
                                <li className="resume__item">Part of management team that set company goals and timelines.</li>
                            </ul>
                            <h3 className="resume__title"><span className="highlighted">Sr. Web Developer</span>: Feb. 2014 - Dec. 2017</h3>
                            <ul className="resume__list">
                                <li className="resume__item">Created custom and tailored web pages for Realtors&#174; within the framework of a proprietary CMS.</li>
                                <li className="resume__item">Worked with the project coordinator and the client to deliver a pleasing final product on a set timeline.</li>
                                <li className="resume__item">Helped streamline the site build process and assisted platform engineers in modernizing the platform for mobile focused development.</li>
                                <li className="resume__item">Involved in hire/fire decisions for all new web developers and interns. Responsible for onboarding process of new staff within the production team.</li>
                                <li className="resume__item">Set up site level tracking in Google Analytics and related suites for clients and internal use.</li>
                                <li className="resume__item">Kanban/Waterfall development methodology with stake holder reviews per project.</li>
                            </ul>
                        </div>
                        <div className="resume__employment">
                            <h2 className="resume__company"><span className="highlighted">Competitive Computing</span>: Sept. 2013 - Feb. 2014</h2>
                            <h3 className="resume__title"><span className="highlighted">System Technician</span></h3>
                            <ul className="resume__list">
                                <li className="resume__item">Provided contracted digital and physical infrastructure setup and support to clients in the education and medical fields.</li>
                                <li className="resume__item">Provided help desk and hardware repair services for the entirety of contracted college campus.</li>
                            </ul>
                        </div>
                        <div className="resume__employment">
                            <h2 className="resume__company"><span className="highlighted">Evergreen Gardens</span>: Summer 2012 &amp; 2013</h2>
                            <h3 className="resume__title"><span className="highlighted">Associate</span></h3>
                            <ul className="resume__list">
                                <li className="resume__item">Created and managed web presence.</li>
                                <li className="resume__item">Managed and tended to yearly chrysanthemum yield.</li>
                                <li className="resume__item">Assisted customers with selection of products.</li>
                            </ul>
                        </div>
                        <div className="resume__employment">
                            <h2 className="resume__company"><span className="highlighted">Airfield Deli</span>: Summer 2011</h2>
                            <h3 className="resume__title"><span className="highlighted">Owner</span></h3>
                            <ul className="resume__list">
                                <li className="resume__item">Independent owner and cook, manager of stock, menu and service.</li>
                            </ul>
                        </div>
                </div>
            </section>

            <section className="main__gutter main__gutter--skills">
                <div className="main__content">
                    <h2>Knowledge Base</h2>
                    <div className="content__panel">
                        <h3>Major Skills</h3>
                        <ul className="resume__list">
                            <li className="resume__item">Web Development</li>
                            <li className="resume__item">Cross-functional Team Leadership</li>
                            <li className="resume__item">Search Engine Optimization</li>
                            <li className="resume__item">AB Testing &amp; UX Research</li>
                        </ul>
                    </div>
                    <div className="content__panel">
                        <h3>Front End</h3>
                        <ul className="resume__list">
                            <li className="resume__item">HTML</li>
                            <li className="resume__item">CSS</li>
                            <li className="resume__item">Sass</li>
                            <li className="resume__item">JS</li>
                            <li className="resume__item">jQuery</li>
                            <li className="resume__item">React</li>
                        </ul>
                    </div>
                    <div className="content__panel">
                        <h3>Back End</h3>
                        <ul className="resume__list">
                            <li className="resume__item">Apache</li>
                            <li className="resume__item">MySQL</li>
                            <li className="resume__item">Laravel</li>
                            <li className="resume__item">PHP</li>
                            <li className="resume__item">Java</li>
                        </ul>
                    </div>
                    <div className="content__panel content__panel--full">
                        <h3>Misc. Tools &amp; Technologies</h3>
                        <ul className="resume__list resume__list--full">
                            <li className="resume__item resume__item--full">Avocode</li>
                            <li className="resume__item resume__item--full">AWS: S3</li>
                            <li className="resume__item resume__item--full">AWS: Route 53</li>
                            <li className="resume__item resume__item--full">AWS: EC2</li>
                            <li className="resume__item resume__item--full">AWS: CodeDeploy/Pipeline</li>
                            <li className="resume__item resume__item--full">AWS: CloudFormation</li>
                            <li className="resume__item resume__item--full">AWS: CloudWatch</li>
                            <li className="resume__item resume__item--full">Adobe Photoshop</li>
                            <li className="resume__item resume__item--full">Adobe Illustrator</li>
                            <li className="resume__item resume__item--full">Adobe Indesign</li>
                            <li className="resume__item resume__item--full">ArcGis</li>
                            <li className="resume__item resume__item--full">Bootstrap</li>
                            <li className="resume__item resume__item--full">Docker</li>
                            <li className="resume__item resume__item--full">DoomBuilder</li>
                            <li className="resume__item resume__item--full">Git</li>
                            <li className="resume__item resume__item--full">Google Analytics</li>
                            <li className="resume__item resume__item--full">Google Search Console</li>
                            <li className="resume__item resume__item--full">Google Tag Manager</li>
                            <li className="resume__item resume__item--full">Google Optimize</li>
                            <li className="resume__item resume__item--full">inVision</li>
                            <li className="resume__item resume__item--full">Jira</li>
                            <li className="resume__item resume__item--full">NPM</li>
                            <li className="resume__item resume__item--full">Unity</li>
                            <li className="resume__item resume__item--full">Wordpress</li>
                        </ul>
                    </div>
                    <div className="content__panel content__panel--full">
                        <h3>Misc. Language Exposure</h3>
                        <ul className="resume__list resume__list--full">
                            <li className="resume__item resume__item--full">ACS</li>
                            <li className="resume__item resume__item--full">C</li>
                            <li className="resume__item resume__item--full">C#</li>
                            <li className="resume__item resume__item--full">C++</li>
                            <li className="resume__item resume__item--full">OCaml</li>
                            <li className="resume__item resume__item--full">Python</li>
                            <li className="resume__item resume__item--full">R</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="main__gutter main__gutter--education">
                <div className="main__content">
                    <div className="resume__employment">
                        <h2 className="resume__company"><span className="highlighted">Education</span></h2>
                        <h3 className="resume__title zero-margin"><span className="highlighted">University of Vermont</span></h3>
                        <p className="zero-margin">Burlington, Vermont</p>
                        <p className="zero-margin">BA Computer Science, 2013</p>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}