import {React, useEffect} from "react";
import elevated from "../resources/ElevatedBoston.jpg";
import campion from "../resources/Campion.jpg";
import broad from "../resources/BroadStreet.jpg";
import doom from "../resources/doom2.gif";
import ux from "../resources/ListView.gif";
import old from "../resources/oldSite.gif";
import map from "../resources/mapGen.gif";
import mapview from "../resources/mapview.png";

export default function ProjectsContent() {

    useEffect (() => {

        class pArena {
          constructor(width,height, color) {
            this.width = width;
            this.height = height;
            this.color = "black";
            this.lScore = 0;
            this.rScore = 0;
          }
        }

        class pBall {
          constructor(x,y,radius,xVelo,yVelo,color) {
            this.x = x;
            this.y = y;
            this.radius = radius;
            this.xVelo = xVelo;
            this.yVelo = yVelo;
            this.color = color;
          }
        }

        class pPaddle {
          constructor(x,y,width,height,yVelo,color) {
            this.x = x;
            this.y = y;
            this.width = width;
            this.height = height;
            this.yVelo = yVelo;
            this.color = color;
          }
        }

        const arena = new pArena(200,200);
        const ball = new pBall(50,107,5,1,0,"white");
        const leftPaddle = new pPaddle(10,87,5,40,0,"white");
        const rightPaddle = new pPaddle(185,87,5,40,0,"white");

        var keysDown = {};

        var c = document.getElementById("canvas");
        var ctx = c.getContext("2d");
        ctx.fillStyle = ball.color;

        window.addEventListener("keydown", function(event) {
          keysDown[event.keyCode] = true;
        });

        window.addEventListener("keyup", function(event) {
          delete keysDown[event.keyCode];
        });

        function getRandomInt(min, max) {
          min = Math.ceil(min);
          max = Math.floor(max);
          return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
        }

        function checkCollisionOffset(ball,paddle){
          //Upper Bounce
          if (ball.y <= paddle.y+5){
            ball.yVelo -= 0.3;
            return ball;
          }
          if (ball.y >= paddle.y+5 && ball.y <= paddle.y+10){
            ball.yVelo -= 0.2;
            return ball;
          }
          //Downer Bounce
          if (ball.y <= paddle.y+paddle.height+5){
            ball.yVelo += 0.3;
            return ball;
          }
          if (ball.y >= paddle.y+paddle.height+5 && ball.y <= paddle.y+paddle.height+10){
            ball.yVelo += 0.2;
            return ball;
          }
        }

        function checkCollision (ball,arena,leftPaddle,rightPaddle){
          //Ball + Side Wall
          if ( ball.x <= 0) {
            ball.x = (arena.width/2);
            ball.y = (arena.height/2);
            ball.xVelo = -getRandomInt(1,2);
            ball.yVelo = getRandomInt(-2,2);
            rightPaddle.y = 87;
            leftPaddle.y = 87;
            arena.rScore++;
            //console.log("R" + arena.rScore);
          }
          if (ball.x >= arena.width-ball.radius) {
            ball.x = (arena.width/2);
            ball.y = (arena.height/2);
            ball.xVelo = getRandomInt(1,2);
            ball.yVelo = getRandomInt(-2,2);
            arena.lScore++;
            //console.log("L" + arena.lScore);
          }
          
          //Ball + Top Wall
          if (ball.y >= arena.height-ball.radius || ball.y <= 0) {
            ball.yVelo = -ball.yVelo;
          }
          //Ball + Right Paddle
          if (ball.x >= rightPaddle.x-ball.radius && ball.y+ball.radius >= rightPaddle.y && ball.y <= rightPaddle.y+rightPaddle.height) {
            ball.xVelo = -ball.xVelo;
            ball = checkCollisionOffset(ball,rightPaddle);
          }
          //Ball + Left Paddle
          if (ball.x === leftPaddle.x+leftPaddle.width && ball.y+ball.radius >= leftPaddle.y && ball.y <= leftPaddle.y+leftPaddle.height) {
            ball.xVelo = -ball.xVelo;
            ball = checkCollisionOffset(ball,leftPaddle);
          }
          //Right Paddle + Top
          if (rightPaddle.y <= 0){
            rightPaddle.y = 0;
          }
          //Right Paddle + Bottom
          if (rightPaddle.y >= arena.height-rightPaddle.height){
            rightPaddle.y = arena.height-rightPaddle.height;
          }
          //Left Paddle + Top
          if (leftPaddle.y <= 0){
            leftPaddle.y = 0;
          }
          //Right Paddle + Bottom
          if (leftPaddle.y >= arena.height-leftPaddle.height){
            leftPaddle.y = arena.height-leftPaddle.height;
          }
        }

        function update(){
          checkCollision(ball, arena, leftPaddle, rightPaddle);
          ball.x += ball.xVelo;
          ball.y += ball.yVelo;
          rightPaddle.y += ball.yVelo;
          
          for(var key in keysDown){
            var value = Number(key);
            if (value === 87) {
              leftPaddle.y -= 2;
            } else if (value === 83) {
              leftPaddle.y += 2;
            }
          }
          
        }

        function draw(){
          ctx.fillStyle = arena.color;
          ctx.fillRect(0,0,arena.width,arena.height);
          
          ctx.fillStyle = ball.color;
          ctx.fillRect(ball.x , ball.y, ball.radius, ball.radius);

          ctx.fillStyle = leftPaddle.color;
          ctx.fillRect(leftPaddle.x, leftPaddle.y, leftPaddle.width, leftPaddle.height);
          
          ctx.fillStyle = rightPaddle.color;
          ctx.fillRect(rightPaddle.x, rightPaddle.y, rightPaddle.width, rightPaddle.height);
        }

        function runLoop(){
          update();
          draw();
          window.requestAnimationFrame(runLoop);
        }

        window.requestAnimationFrame(runLoop);

    });

    return (
        <section className="main__gutter">
            <div className="main__content">
                <h1><span className="highlighted">A List of Projects</span></h1>
                <div className="content__card">
                    <h2 className="title"><span className="highlighted">Union Street Media</span></h2>
                    <div className="content__fake-lightbox content__fake-lightbox--full-width">
                        <img className="content__image" src={campion} alt="Campion Screen Grab"/>
                        <img className="content__image" src={elevated} alt="Elevated Screen Grab"/>
                        <img className="content__image" src={broad} alt="Broad Street Screen Grab"/>
                    </div>
                    <h3 className="title"><span className="highlighted">USM Web Development</span></h3>
                    <p>Since February 2014 I've been employeed with Union Street Media out of Burlington Vermont.  In that time I've worked on well beyond 138 web sites.  I only say "well beyond" because I gave up counting in 2016.  As time continued there was a continual shift to more complex sites instead of simpler business cards with IDX integration.  During my initial years with the company I'm proud to be part of the team to setup their responsive website platform and then further refine it into the examples seen above.  More recently many of these sites would go on to win industry awards for best mobile experience.</p>

                    <h3 className="title"><span className="highlighted">USM Engineering</span></h3>
                    <div className="content__fake-lightbox">
                        <img className="content__image" src={mapview} alt="Map View"/>
                    </div>
                    <p>Behind the designers and web developers who make the individual sites are the teams that build and support the platform everything lives on.  For the larger platform mondernization project, I filled the role of front-end engineer on a project to overhaul our results search pages.  The results are the above which updated our antequated desktop only map search and brings that functionality to mobile responsively.</p>
                    <p>The project uses React as it's UI layer to present data from our legacy PHP backend and our newer listings API.</p>

                    <h3 className="title"><span className="highlighted">USM Technical SEO</span></h3>
                    <p>I still haven't come up with a great short analogy to explain Technical SEO to someone not familiar with it.  It doesn't replace regular "on-page" SEO, but it does compliment it.  Consider a whole bunch of (mostly) small optimizations you can make to your site and server to allow search engines to crawl your site more efficiently and provide a smoother experience to end users.  These optimizations could be utilizing schema to describe elements of your site, making sure you're delivering as small a file size possible in your request, caching static resources, or minimizing latency between resources in your server environment.</p>
                    <p>After returning to Engineering work a large portion of my time was devoted to implementing Technical SEO improvements such as the above across the USM platform.  Additional individual attention was provided at the request of Account Managers.</p>

                    <h3 className="title"><span className="highlighted">USM UX and AB Testing</span></h3>
                    <div className="content__fake-lightbox">
                        <img className="content__image" src={ux} alt="UX Experiment Screen Grab"/>
                    </div>
                    <p>While I am not a UX designer, it is an important factor for me.  Something that started unofficially and then later became an official responsibility was testing for user experience.  At the start I'd simply build a proof of concept I'd want to try and then pitch it to a designer.  If the designer liked my proposal they'd eventually included the concept on a production site. I'd then add additional tracking to the final site via Google Analytics to compare the proof of concept to existing sites.  As time went on the level of collaboration increased to the point that we created an internal AB testing team with the bandwidth to pose a hypothesis, pitch a design, build the test case, and then analyze the experiment result.</p>
                </div>

                <div className="content__card">
                    <h3 className="title"><span className="highlighted">iD Tech 1 Experiments</span></h3>
                    <div className="content__fake-lightbox">
                        <img className="content__image" src={doom} alt="Doom Sidescroller Screen Grab"/>
                    </div>
                    <p>Everyone has a pet project, working with the various games made in the iD Tech 1 engine is mine.  Restrictions breed creativity, right?  One project was to make a random maze generator with syntax that doesn't allow for pop and push functions.  Another was turning the 2.5D game engine into a side scrolling platformer.  Ever seen a turn based JRPG combat system in Doom?  How about tic-tac-toe?  Fun little side projects to work on.</p>
                </div>

                <div className="content__card">
                    <h3 className="title"><span className="highlighted">Misc Experiments</span></h3>
                    <div className="content__fake-lightbox content__fake-lightbox--half-width">
                        <canvas id="canvas" height="200px" width="200px"></canvas>
                        <img className="content__image" src={map} alt="Simplex Noise Demo Screen Grab"/>
                    </div>
                    <p>To keep sharp on JS skills I decided to make a pong demo with a medium I see frequently but don't use, &#60;canvas&#62;.  The above should be functional using the "w" and "s" keys, the ball will accelerate if you bounce it with the half of the paddle along it's current path and decelerate if you bounce it with the half closer to the direction it is coming from.</p>
                    <p>Elsewhere my first sandbox to learn React utilized simplex noise to create a terrain generator app.  Unfortunatly the entire app was constructed with React, output included.  Rendering a million one by one pixel components to simulate... ... well, pixels... ...appears to have some memory overhead... Should I ever revisit the project I'll look into displaying the output either as a rendered image or into a canvas element.</p>
                </div>

                <div className="content__card">
                    <h3 className="title"><span className="highlighted">Evolutionary Robotics</span></h3>
                    <div className="content__fake-lightbox content__fake-lightbox--video">
                        <div className="aspect">
                        <iframe title="Evolutionary Robotics via YouTube"src="https://www.youtube.com/embed/4bgRoHJ72mc" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                    <p>A sample clip from a program written to evolve the locomotive gait of a simple robot. Each loop the program would average two parent arrays to create an offspring array as a simulation of chromosomal crossover. Fitness values would be assigned to each child based on how far it moved from the origin point in the sample period. The highest fitness offspring of a generation would then be put into a pool to act as the next generation's parents. This one ended up with a particularly odd movement pattern due to a set of absolutley tiny legs.</p>
                </div>

                <div className="content__card">
                    <h3 className="title"><span className="highlighted">My Old Website</span></h3>
                    <div className="content__fake-lightbox">
                        <img className="content__image" src={old} alt="Old Website Screen Grab"/>
                    </div>
                    <p>My old website was stitched together with PHP includes, jQuery and styled to looks like a DOS program installer.  I thought it was funny, however it's target demographic was very narrow.  Out with the old and in with the new React version.</p>
                </div>
            </div>
        </section>
    );
}