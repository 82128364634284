import React from "react";

export default function MainComponent() {
    return (
        <section className="main__content">
            <div className="main__gutter">
                <h1><span className="content__Headline">Cameron Wollensak</span></h1>
                <p>I'm a professional web developer with a strong understanding of the front-end tech stack who has more recently been working as a software engineer.</p>
                <p>In my free time you can find me running a local board game meet-up, experimenting with gameplay mechanics, or enjoying the sun on a bike or pair of skis.</p>
            </div>
        </section>
    );
}