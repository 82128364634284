import logo from "../resources/logoWhite.png";
import React from "react";
import {
  NavLink,
  Link
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHamburger, faTimes } from '@fortawesome/free-solid-svg-icons';

export default function Header() {

    const [isOpen, setOpen] = React.useState(false);

    const useViewport = () => {
        const [width, setWidth] = React.useState(window.innerWidth);

        React.useEffect(() => {
            const handleWindowResize = () => setWidth(window.innerWidth);
            window.addEventListener("resize", handleWindowResize);
            return () => window.removeEventListener("resize", handleWindowResize);
        }, []);

        // Return the width so we can use it in our components
        return { width };
    }

    const { width } = useViewport();
    const mobile = 992;

    function handleNav() {
        if (isOpen === true) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }

    return (
        <React.Fragment>
            {
                width < mobile
                ? <button type="button" className="hamburger" onClick={handleNav}>
                    {isOpen
                            ? <FontAwesomeIcon icon={faTimes} />
                            : <FontAwesomeIcon icon={faHamburger} />
                    }
                   </button>
                : null
            }
            <header className={ width < mobile 
                                        ? "header header__mobile-"+isOpen
                                        : "header"
                                    }>
                <div className="header__gutter">
                    <Link 
                        to="/"
                        exact
                        className="logo__link"
                        onClick={handleNav}>
                        <img className="logo_img" src={logo} alt="logo"/>
                    </Link>
                    <nav className="navigation">
                        <ul className="navigation__list">
                            <li className="navigation__item">
                                <NavLink 
                                to="/"
                                exact
                                className="navigation__link"
                                activeClassName="navigation__link--active"
                                onClick={handleNav}>
                                Home
                                </NavLink>
                            </li>
                            <li className="navigation__item">
                                <NavLink 
                                to="/projects"
                                className="navigation__link"
                                activeClassName="navigation__link--active"
                                onClick={handleNav}>
                                Projects
                                </NavLink>
                            </li>
                            <li className="navigation__item">
                                <NavLink 
                                to="/resume"
                                className="navigation__link"
                                activeClassName="navigation__link--active"
                                onClick={handleNav}>
                                Resume
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
        </React.Fragment>
    );
}